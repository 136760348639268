<template>
  <v-container class="py-0 py-sm-8 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-4">
        <v-card class="d-flex align-center justify-center pa-4 fill-width fill-height indigo" dark outlined @click.prevent="ACCOUNT_SHOW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Customer
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-6 white">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class="rounded-0"
            placeholder="Search (Account Name, Email, Phone)"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-2 white">
        <div class="pa-4 pl-sm-0">
          <!-- <v-select
            v-model="tableSearchStatus"
            :items="[{ name: 'All Status', value: null }, { name: 'Active', value: 1 }, { name: 'Not Active', value: 1 }, { name: 'Ready To Ship', value: 2 }, { name: 'Shipped', value: 3 }, { name: 'Complete', value: 4 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Order Status"
          /> -->
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [8, 15, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="d-flex align-center text-uppercase">
              <v-icon class="mr-1">
                mdi-barcode
              </v-icon>
              <span class="font-weight-bold caption">
                GMJ/CUST/{{ item.id }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="py-2">
              <div>
                {{ item.name }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.store_name`]="{ item }">
            <div>
              {{ item.store_name }}
            </div>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <div>
              {{ item.role }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.status) === 100 ? 'red' : parseInt(item.status) === 99 ? 'yellow darken-2' : parseInt(item.status) === 1 ? 'green' : 'grey'" outlined dark small>
                {{ parseInt(item.status) === 100 ? 'Deleted' : parseInt(item.status) === 99 ? 'Blocked' : parseInt(item.status) === 1 ? 'Active' : 'Not Active' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager'], $store.state.user.role)" depressed small dark color="orange" class="rounded-0 text-capitalize" @click.prevent="ACCOUNT_SHOW(item)">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogAccount.show"
      persistent
      scrollable
      max-width="290"
    >
      <v-card v-if="dialogAccount.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogAccount.data.id ? 'Update Account' : 'Create New Account' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-text-field
            v-model="dialogAccount.data.name"
            outlined
            dense
            hide-details
            label="Nama"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogAccount.data.email"
            outlined
            dense
            hide-details
            label="Email"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogAccount.data.phone"
            outlined
            dense
            hide-details
            label="No.HP / Whatsapp"
            class="mb-4 rounded-0"
            @keypress="$NumOnly"
          />
          <v-text-field
            v-model="dialogAccount.data.phone2"
            outlined
            dense
            hide-details
            label="No.HP / Whatsapp 2"
            class="mb-4 rounded-0"
            @keypress="$NumOnly"
          />
          <v-select
            v-model.number="dialogAccount.data.status"
            :items="[
              { name: 'Active', value: 1 },
              { name: 'Not Active', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Account Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogAccount.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="ACCOUNT_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
let searchInterval = null
export default {
  name: 'Customer',
  data: () => ({
    headers: [
      {
        text: 'CUST.ID',
        value: 'id'
      },
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Phone1', value: 'phone' },
      { text: 'Phone2', value: 'phone2' },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    options: {},
    dialogAccount: {
      show: false,
      data: null
    }
  }),
  computed: {
  },
  watch: {
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.ACCOUNTS_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    storeActive (v) {
      if (v) {
        this.ACCOUNTS_GET(this.tableSearch)
      }
    },
    options () {
      this.ACCOUNTS_GET(this.tableSearch)
    }
  },
  mounted () {
    this.ACCOUNTS_GET()
  },
  methods: {
    ACCOUNT_SHOW (account) {
      if (account) {
        this.dialogAccount.data = Object.assign({}, account)
      } else {
        this.dialogAccount.data = {
          id: null,
          name: '',
          email: '',
          phone: '',
          phone2: '',
          status: 1
        }
      }
      this.dialogAccount.show = true
    },
    ACCOUNTS_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('customer/CUSTOMER_SEARCH', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '')).then((res) => {
        if (res.status) {
          this.table = res.data.data.data
          this.tableTotal = parseInt(res.data.data.total) || 0
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    ACCOUNT_PROCESS () {
      const processedAccount = Object.assign({}, this.dialogAccount.data)
      if (!this.$validateName(processedAccount.name)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan nama yang valid!' })
        return false
      }
      if (!this.$validateEmail(processedAccount.email)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Email tidak valid!' })
        return false
      }
      if (!this.$validatePhone(processedAccount.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No HP tidak valid!' })
        return false
      }
      processedAccount.phone = this.$reformatPhone(processedAccount.phone)
      processedAccount.role = 'customer'
      this.$store.dispatch('customer/CUSTOMER_PROCESS_BY_ADMIN', processedAccount)
        .then((res) => {
          if (res.status) {
            if (res.data.data.id) {
              this.ACCOUNTS_GET(this.tableSearch)
              this.dialogAccount.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedAccount.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    }
  }
}
</script>
